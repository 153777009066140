/*******************************
    User Variable Overrides
*******************************/

.ui.modal .ui.basic.button  {
    box-shadow: none !important;
}

.ui.styled.accordion .ui.basic.button  {
    box-shadow: none !important;
}

.ui.basic.primary.button.no-outline  {
    box-shadow: none !important;
}